<template>
    <main>
        <div class="container-fluid px-4">
            <h4 class="mt-4">
                IUSA WATER
            </h4>
            <div class="card" style="padding: 20px">
                <div class="col-xl-12">
                    <!--<img :src="'img/logo_iusa.svg'" style="margin-right: 10px;"/>-->
                    <img :src="'img/logo_iusawater.png'" />
                </div>
                <div class="row">
                    <p>Conoce <strong>IUSA WATER.</strong></p>
                    <p>IUSA WATER es un Servicio de medición de agua, manéjalo desde tu smartphone.</p>
                    <p>No más problemas, no más facturas, pago desde casa.</p>
                    <p><strong>Satisfacción del cliente Garantizada.</strong></p>
                    <div class="col-xl-12 d-flex justify-content-center">
                        <video src="video/iusawather.mp4" poster="img/poster.jpg" width="500" height="300"
                            controls></video>
                    </div>
                    <div class="col-xl-12 d-flex justify-content-center">
                        <div>
                            <a href="https://iusawater.com/" target="_blank">
                                <img :src="'img/logo_iusawater.png'" style="padding:20px; margin-right: 30px;" /><br>
                                <span class="d-flex justify-content-center">Website</span>
                            </a>
                        </div>
                        <div>
                            <a href="https://play.google.com/store/apps/details?id=com.iusa.water&hl=es_MX" target="_blank">
                                <img :src="'img/icon_app.png'"
                                    style="width:150px;padding:20px; margin-right: 30px;" /><br>
                                <span class="d-flex justify-content-center">App en Android</span>
                            </a>
                        </div>
                        <div>
                            <a href="https://apps.apple.com/mx/app/iusa-water/id1490499930" target="_blank">
                                <img :src="'img/icon_app.png'"
                                    style="width:150px;padding:20px; margin-right: 30px;" /><br>
                                <span class="d-flex justify-content-center">App en iOS</span>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
            <p></p>
        </div>
    </main>
</template>
<script>
export default {
    name: "SAPCourse",
    data() {
        return {
            data: null,
        };
    },
};
</script>
<style>
button.page-link {
    display: inline-block;
}

.resaltado {
    font-weight: 500;
    color: #ff0000
}
</style>